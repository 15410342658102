<template>
  <div class="bio_link_main_view">

    <div class="view_inner d-flex flex-column">

      <div class="view_top d-flex align-items-center">
        <h4 class="title">Audio Block</h4>
        <div class="view-right d-flex align-items-center ml-auto">
          <bio-shortened-url></bio-shortened-url>
          <router-link class="cross_icon ml-auto" :to="{name: 'bio_links'}">
            <i class="fal fa-times"></i>
          </router-link>
        </div>
      </div>

      <div class="view_center">
        <div class="steps_dot mb-3">
          <router-link :to="{name: 'select_content'}"
                       class="btn---cta light-blue btn-round">
            <span>Go Back</span>
          </router-link>
          <div class="circles d-inline-block text-center">
            <span class="dotted_circle active"></span>
          </div>

          <button @click.prevent="validateAndStoreBlock()" class="btn---cta btn-blue btn-round">
            <span> {{ !!getBioLinkAudioSectionAdd._id ? 'Update' : 'Save' }} Block</span>
          </button>
        </div>

        <div class="input-block-list">
          <div class="block-heading">
            <h2>🔊 Audio</h2>
            <p>Add audio link to your profile with the Audio Block. Use this block to add engaging audio content.</p>
          </div>

          <!-- add links section -->
          <div class="add-block-list">
            <div class="add-block-item">
              <div class="block-inner">
                <div
                  class="input_icon_text mb-3 p-1 d-flex justify-content-around align-items-center profile__box">
                  <img :width="item.width" v-for="item in audioRegexArray" v-tooltip="item.name" :src="item.image"/>
                </div>
                <!-- Music player link -->
                <div
                  :class="{'input-field-error': audioValidation.url || audioValidation.urlValid || audioValidation.urlLength || audioValidation.isValidAudio}"
                  class="input_icon_text d-flex align-items-center profile__box">
                  <div class="content">
                    <div class="url">
                      <div class="avatar mr-1">
                        <i class="fal fa-link"></i>
                      </div>
                      Add a supported Music Player link
                    </div>
                    <div class="name">
                      <div class="validation_input d-flex">
                        <img v-if="audioObj && audioObj.image" :width="audioObj.width" :src="audioObj.image"/>
                        <url
                          class="ml-2"
                          v-model="getBioLinkAudioSectionAdd.url"
                          :validations="audioValidation"
                          v-bind:blur="() => getBioLinkAudioSectionAdd.url = focusoutConcatenation(getBioLinkAudioSectionAdd.url)"
                          placeholder="e.g. https://w.soundcloud.com/player/?url=https://soundcloud.com/khurramashari/coke-studio-season-14-tu-jhoom-naseebo-lal-x-abida-parveen&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></url>
                      </div>
                    </div>
                  </div>
                </div>
                <span class="input-error" v-if="audioValidation.url">{{ URLMessages.url }}</span>
                <span class="input-error" v-else-if="audioValidation.urlLength">{{ URLMessages.urlLength }}</span>
                <span class="input-error" v-else-if="audioValidation.urlValid">{{ URLMessages.urlValid }}</span>
                <span class="input-error"
                      v-else-if="audioValidation.isValidAudio">Add a supported Music Player link</span>
                <!-- Title input -->
                <div :class="{'input-field-error': titleLength}" class="mt-3 input_icon_text d-flex align-items-center">
                  <div class="content">
                    <div class="d-flex url">
                      <div class="avatar mr-1">
                        <i class="fal fa-align-left"></i>
                      </div>
                      Title
                    </div>
                    <div class="name">
                      <div class="validation_input">
                        <input type="text" placeholder="Add a Title..." v-model="getBioLinkAudioSectionAdd.title"
                               data-cy="link-title"/>
                      </div>
                    </div>
                  </div>
                </div>
                <span class="input-error" v-if="titleLength">Title should not be greater than 150 characters</span>
                <!-- height component -->
                <height-component
                  @heightObj="heightObj($event)"
                  :height_enabled="getBioLinkAudioSectionAdd.height_enabled"
                  :height="getBioLinkAudioSectionAdd.height"
                ></height-component>

                <!-- Schedule component -->
                <schedule-component
                  ref="schedule"
                  :enabled="getBioLinkAudioSectionAdd.schedule_enabled"
                  :data="getBioLinkAudioSectionAdd.schedule"
                ></schedule-component>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import {mapActions, mapGetters} from 'vuex'
import BioShortenedURL from './BioShortenedURL'

import {
  URLMessages,
  audioValidation,
  audioRegexArray
} from '../../../../../common/attributes'

export default ({
  data () {
    return {
      audioValidation: {...audioValidation},
      URLMessages: URLMessages,
      titleLength: false,
      audioRegexArray: audioRegexArray,
      audioObj: {
        name: '',
        image: '',
        width: ''
      }
    }
  },
  created () {
    this.isBioLinkFirstStepVerified()
  },
  components: {
    'bio-shortened-url': BioShortenedURL,
    'url': () => import('@/ui/URL'),
    'height-component': () => import('@/views/pages/bio-link/widgets/snippets/bio-common-components/HeightComponent'),
    'schedule-component': () => import('@/views/pages/bio-link/widgets/snippets/bio-common-components/ScheduleComponent')
  },
  computed: {
    ...mapGetters([
      'getBioLinkAudioSectionAdd',
      'getProfile'
    ])
  },
  methods: {
    ...mapActions(['storeBioContentSection']),
    async validateAndStoreBlock () {
      this.audioValidation.url = true
      this.audioValidation.isValidAudio = false

      if (this.getBioLinkAudioSectionAdd.url && this.getBioLinkAudioSectionAdd.url.length > 8) {
        this.audioValidation.url = false
        this.getBioLinkAudioSectionAdd.url = this.urlConcatenation(this.getBioLinkAudioSectionAdd.url)
        this.audioValidation.urlLength = !this.maxLength(this.getBioLinkAudioSectionAdd.url, 500)
        this.audioValidation.urlValid = !this.isValidURL(this.getBioLinkAudioSectionAdd.url)
      }

      let urlCheck = Object.keys(this.audioValidation).every(k => this.audioValidation[k] === false)
      if (!urlCheck) {
        await this.$store.dispatch('toastNotification', {message: 'Url validation failed.', type: 'error'})
        return
      }

      this.audioValidation.isValidAudio = !(!!this.audioRegexArray.find(item => this.getBioLinkAudioSectionAdd.url.match(item.regex)))
      if (this.audioValidation.isValidAudio) {
        await this.$store.dispatch('toastNotification', {message: 'Add a supported Music Player link.', type: 'error'})
        return
      }

      this.titleLength = !this.getBioLinkAudioSectionAdd.title ? false : !this.maxLength(this.getBioLinkAudioSectionAdd.title, 150)
      if (this.titleLength) {
        await this.$store.dispatch('toastNotification', {message: 'Title validation failed.', type: 'error'})
        return
      }

      const scheduleComponentRef = this.$refs.schedule
      const proceed = await scheduleComponentRef.checkScheduleSubmission()
      if (!proceed) {
        return
      }

      this.getBioLinkAudioSectionAdd.schedule_enabled = scheduleComponentRef.schedule_enabled
      if (scheduleComponentRef.schedule_enabled) {
        this.getBioLinkAudioSectionAdd.schedule = scheduleComponentRef.schedule
      }

      let res = await this.storeBioContentSection('getBioLinkAudioSectionAdd')
      if (res && res.data.status) {
        this.$router.push({name: 'select_content'})
      }

      if(res && !res.data.status) {
        this.alertMessage(res.data.message, 'error')
      }

    },
    heightObj (event) {
      this.getBioLinkAudioSectionAdd.height_enabled = event.height_enabled
      this.getBioLinkAudioSectionAdd.height = event.height
    },
  },
  watch: {
    'getBioLinkAudioSectionAdd.title' (value) {
      this.titleLength = !value ? false : !this.maxLength(value, 150)
    },
    'getBioLinkAudioSectionAdd.url' (value) {
      const logo = this.audioRegexArray.find(item => this.getBioLinkAudioSectionAdd.url && this.getBioLinkAudioSectionAdd.url.match(item.regex))
      this.audioObj = logo ? logo : null
      this.audioValidation.isValidAudio = false
      if (value && value.length) {
        this.audioValidation.isValidAudio = !logo
      }
    }
  }

})
</script>
